import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { FC } from 'react'

import Container from '../components/Atoms/Container'
import Hero from '../components/Atoms/Hero'
import Wrapper from '../components/Atoms/Wrapper'
import * as variable from '../components/tokens'
import EvenColumns from '../components/Molecules/EvenColumns'
import Form from '../components/Molecules/Form'
import Entry from '../components/Templates/Entry'
import Column from '../components/Atoms/Column'
import Row from '../components/Atoms/Row'
import { ResponsiveEmbed } from '../components/Atoms/ResponsiveEmbed'

import CheckaTrade from '../assets/checkatrade-strapline.png'
import styled from 'styled-components'

export interface IUpsellProps {
  id: string
  upsellTitle: string
  upsellImage: FluidObject
  upsellIcon: {
    url: string
  }
  upsellContentNode: {
    childMarkdownRemark: {
      html: string
    }
  }
}
export interface IUpsellFCProps {
  index: number
  upsell: IUpsellProps
}

export interface IDatoCmsTestimonialProps {
  id: string
  attribution: string
  contentNode: {
    childMarkdownRemark: {
      html: string
    }
  }
}

export interface IIndexPageProps {
  data: {
    datoCmsHome: {
      featuredVideoEmbed: string
      valueProposition: string
      valuePropositionExplanationNode: {
        childMarkdownRemark: {
          html: string
        }
      }
      upsells: IUpsellProps[]
      conversionPointNode: {
        childMarkdownRemark: {
          html: string
        }
      }
    }
    allDatoCmsTestimonial: {
      edges: Array<{
        node: IDatoCmsTestimonialProps
      }>
    }
  }
}

export const frontmatter = {
  title: 'Home',
  path: '/',
  description: '',
  secondaryNavMenu: 'About',
  secondaryNavOrder: 1,
}

const QuoteFooter = styled.footer`
  max-width: 30rem;
  margin: 0 auto;
`

const UpSell: FC<IUpsellFCProps> = (props, index) => {
  const { upsell } = props
  return (
    <Column
      paddingBottomSmall={variable.size.triple}
      paddingTopSmall={variable.size.triple}
      paddingBottom={variable.size.sextuple}
      paddingTop={variable.size.sextuple}
    >
      <Row width={2} swapColumns={index === 1 && true}>
        <Column>
          {upsell.upsellTitle ? <h2>{upsell.upsellTitle}</h2> : null}
          {upsell.upsellContentNode ? (
            <p
              dangerouslySetInnerHTML={{
                __html: upsell.upsellContentNode.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </Column>
        {upsell.upsellImage ? (
          <Column>
            <div style={{ textAlign: 'center' }}>
              <Img fluid={upsell.upsellImage} />
            </div>
          </Column>
        ) : null}
        {upsell.upsellIcon ? (
          <Column style={{ margin: 'auto' }}>
            <img src={upsell.upsellIcon.url} width="120" height="120" />
          </Column>
        ) : null}
      </Row>
    </Column>
  )
}

const IndexPage: FC<IIndexPageProps> = ({ data }) => {
  const { datoCmsHome, allDatoCmsTestimonial } = data
  return (
    <Entry
      pageTitle={datoCmsHome.valueProposition}
      pageDescription={datoCmsHome.valuePropositionExplanationNode.childMarkdownRemark.html}
    >
      <Wrapper id="masthead">
        <Container>
          <EvenColumns
            bufferBottom={variable.size.sextuple}
            bufferTop={variable.size.sextuple}
            content={[
              {
                innerContent: (
                  <>
                    {datoCmsHome.valueProposition ? (
                      <h1>{datoCmsHome.valueProposition}</h1>
                    ) : (
                      <h1>Quality Window Cleaning</h1>
                    )}
                    {datoCmsHome.valuePropositionExplanationNode ? (
                      <h2
                        dangerouslySetInnerHTML={{
                          __html:
                            datoCmsHome.valuePropositionExplanationNode.childMarkdownRemark.html,
                        }}
                      />
                    ) : null}
                  </>
                ),
              },
              {
                innerContent: <ResponsiveEmbed videoId={datoCmsHome.featuredVideoEmbed} />,
              },
            ]}
          />
        </Container>
      </Wrapper>
      <Wrapper
        id="value-proposition"
        backgroundColour={variable.colors.neutral.dark}
        textColour={variable.colors.base.primary}
      >
        <Container>
          {datoCmsHome.upsells.map((upsell, index) => (
            <UpSell key={index} upsell={upsell} index={index} />
          ))}
        </Container>
      </Wrapper>
      {allDatoCmsTestimonial?.edges.length ? (
        <Wrapper id="testimonials">
          <Hero>
            <blockquote>
              <h3
                dangerouslySetInnerHTML={{
                  __html: allDatoCmsTestimonial?.edges[0].node.contentNode.childMarkdownRemark.html,
                }}
              />
              <p>
                <i>{allDatoCmsTestimonial?.edges[0].node.attribution}</i>
              </p>
              <QuoteFooter>
                <hr />
                <p>Find more reviews on</p>
                <a href="https://www.checkatrade.com/trades/HeadturnerWindowCleaning/">
                  <img src={CheckaTrade} alt="Check a trade logo" width="100%" height="auto" />
                </a>
              </QuoteFooter>
            </blockquote>
          </Hero>
        </Wrapper>
      ) : null}
      <Wrapper id="contact-us">
        <Container>
          <EvenColumns
            bufferTop={variable.size.quad}
            content={[
              {
                innerContent: (
                  <>
                    <h2>Get in touch with us today for a competitive quote!</h2>
                    <Form />
                  </>
                ),
              },
              {
                innerContent: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: datoCmsHome.conversionPointNode.childMarkdownRemark.html,
                    }}
                  />
                ),
              },
            ]}
          />
        </Container>
      </Wrapper>
    </Entry>
  )
}
export default IndexPage

export const query = graphql`
  {
    datoCmsHome {
      featuredVideoEmbed
      valueProposition
      valuePropositionExplanationNode {
        childMarkdownRemark {
          html
        }
      }
      upsells {
        id
        upsellTitle
        upsellImage {
          fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        upsellIcon {
          url
        }
        upsellContentNode {
          childMarkdownRemark {
            html
          }
        }
      }
      conversionPointNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allDatoCmsTestimonial {
      edges {
        node {
          attribution
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
