import React, { FC } from 'react'
import styled from 'styled-components'

export interface IResponsiveEmbedProps {
  videoId: string
}

const ResponsiveContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const ResponsiveEmbed: FC<IResponsiveEmbedProps> = ({ videoId }) => (
  <ResponsiveContainer>
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </ResponsiveContainer>
)
