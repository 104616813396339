import styled from 'styled-components'

import Container from './Container'

export interface IHeroProps {
  whiteText?: boolean
}

const Hero = styled(Container)<IHeroProps>`
  text-align: center;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ whiteText }) =>
    whiteText &&
    `
    color: white;
  `}
`
export default Hero
